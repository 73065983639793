<template>
    <div class="min-h-screen bg-white py-8 px-4 sm:px-6 lg:px-8">
      <div class="max-w-screen-xl mx-auto">
        <h1 class="text-3xl sm:text-4xl lg:text-5xl font-bold text-navy-900 mb-6 mt-8 text-center">Thy will and not mine</h1>
        <div class="mb-8">
          <img 
            src="https://via.assets.so/img.jpg?w=1600&h=800&tc=%23cecece&bg=%23cecece" 
            alt="Jesus surrounded by disciples" 
            class="w-full h-auto object-cover shadow-md"
          />
        </div>
        <div class="space-y-6 text-base sm:text-lg lg:text-xl text-gray-700 max-w-5xl mx-auto">
          <p>
            I was discussing with a friend recently on matters life, and as our conversation was rolling on issues came up 
            that left me pondering about it long after the conversation.
          </p>
          <p>
            Amazingly, my friend and I never seem to agree on anything (haha) but this time we seemed to 
            be working towards the same conclusion though from different angles. We reason we agreed is because we've been in 
            the same situations at certain points in our lives.
          </p>
          <p>
            Have you ever been wondering what my friend and I agreed to? Well let me tell you, it's "let's say THY 
            WILL BE DONE" that's what we both through. Maybe you can relate to our situations or you've ever listened to 
            a friend who narrated a similar story.
          </p>
          <p>
            Ever wondered why it's important to let God's will be done in your life? Maybe you're asking "what's God's 
            will for me?" - ever wanted to just figure it out on your own? Let me assure you that God's will for your life is good, 
            that's what prophets declare us. That's all I'm saying and wishing them from where there were contradictions 
            to where there's agreement between my friend and I.
          </p>
          <p>
            I know how you want to say "Thy will God" in all your ways, it's probably quoted Psalm 16:5-11 as good 
            living does lie without Him those verses say it's a bitterness. Maybe you can quote it! "I trust God 
            you shall lead me to the door shall be opened to me, ask and it shall be given to those who seek shall find" 
            that's the reference I remember from your favorite passage as you asked for, what you'll be asking for God's will!
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ThyWillNotMine'
  }
  </script>